<template>
  <div class="settings">
    <div
      class="avatar--settings-contianer"
      :class="border ? 'avatar-border' : null"
    >
      <span
        v-if="showDelete"
        class="delete-img-icon"
        @click="$emit('deleteAvatar')"
      ><icon
        class="icon"
        icon="times"
      /></span>
      <img
        class="settings-avatar"
        :class="statusIndicator"
        :src="src"
        :height="height"
        :width="width"
      >
    </div>
  </div>
</template>
<script>
import UserState from '@/Services/User/user.service';

export default {
  name: 'Avatar',
  props: {
    src: {
      type: String,
      required: false,
      default: '../../assets/logo/logo.svg',
    },
    height: {
      type: String,
      required: false,
      default: '100px',
    },
    width: {
      type: String,
      required: false,
      default: '100px',
    },
    border: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    aria: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusIndicator() {
      return UserState?.userStatus?.BackgroundColor || null;
    },
  },
};
</script>
<style lang="scss" >
.settings {
  .avatar {
    margin: 0 auto;
    padding: 0.5rem;
  }

  .avatar--settings-contianer {
    position: relative;

    &.avatar-border {
      border-radius: 50%;
      border: 1px solid var(--primary-color);
    }

    .settings-avatar {
      position: relative;
      z-index: 0;
      border-radius: 50%;
      padding: 0.4rem;

      // &.green {
      //   border: 0.2rem solid #1d9f8f;
      // }

      // &.red {
      //   border: 0.2rem solid #da1e5e;
      // }

      // &.blue {
      //   border: 0.2rem solid #206173;
      // }

      // &.yellow {
      //   border: 0.2rem solid #f79321;
      //   color: white;
      // }

      // &.gray {
      //   border: 0.2rem solid #e1e3e4;
      // }
    }

    .delete-img-icon {
      position: absolute;
      z-index: 1;
      height: 24px;
      width: 24px;
      right: 4px;
      color: white;
      background-color: #1d9f8f;
      border: 2px solid white;
      border-radius: 50%;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        height: 1rem;
        margin: auto auto;
        display: flex;
        max-height: 1rem;
        color: white;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
