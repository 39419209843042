<template>
  <div class="settings">
    <Header
      class="flex"
      :title="$t('Components.Settings.settings')"
    >
      <h5 class="header-title">
        {{ $t('Components.Settings.settings') }}
      </h5>
    </Header>

    <div class="scrollable">
      <Card position="bottom">
        <!-- pin side view -->
        <div
          class="settings_link--container"
        >
          <div class="content-container">
            <RoundResourceIcon
              src="/icons/pin_view.svg"
              type="settings reverse"
            />
            <div class="setting_link--content-container">
              <h6 class="setting_link--header">
                {{ $t('Components.Settings.pin_side_page') }}
              </h6>
              <p class="setting_link--text small-regular elipsis">
                {{ $t('Components.Settings.pin_side_page_description') }}
              </p>
            </div>
          </div>
          <div class="end-icon">
            <BFormCheckbox
              v-model="pinSidePage"
              switch
              size="lg"
            />
          </div>
        </div>

        <!-- revert to default camera view -->
        <div
          class="settings_link--container"
          @click="MapNavigatorController.resetCameraView()"
        >
          <div class="content-container">
            <RoundResourceIcon
              src="/icons/default_view.svg"
              type="settings reverse"
            />
            <div class="setting_link--content-container">
              <h6 class="setting_link--header">
                {{ $t('Components.Settings.map_camera_default_view') }}
              </h6>
              <p class="setting_link--text small-regular elipsis">
                {{ $t('Components.Settings.map_camera_default_view_description') }}
              </p>
            </div>
          </div>
        </div>

        <!-- save camera view -->
        <div
          class="settings_link--container"
          @click="MapNavigatorController.saveCameraView()"
        >
          <div class="content-container">
            <RoundResourceIcon
              src="/icons/save_view.svg"
              type="settings reverse"
            />
            <div class="setting_link--content-container">
              <h6 class="setting_link--header">
                {{ $t('Components.Settings.map_camera_save_view') }}
              </h6>
              <p class="setting_link--text small-regular elipsis">
                {{ $t('Components.Settings.map_camera_save_view_description') }}
              </p>
            </div>
          </div>
        </div>

        <!-- navigator marker settings -->
        <div>
          <router-link
            class="settings_link--container"
            to="/settings/navigator/position"
          >
            <div class="content-container">
              <RoundResourceIcon
                src="/icons/navigator_position.svg"
                type="settings reverse"
              />
              <div class="setting_link--content-container">
                <h6 class="setting_link--header">
                  {{ $t('Components.Settings.navigator_screen_position') }}
                </h6>
                <p class="setting_link--text small-regular elipsis">
                  {{ $t('Components.Settings.navigator_position_description') }}
                </p>
              </div>
            </div>
            <div class="end-icon icon-size-small setting_icon--vcenter">
              <svg-icon src="/icons/arrow_right.svg" />
            </div>
          </router-link>
        </div>

        <!-- logout -->
        <div @click="signOut()">
          <div class="settings_link--container">
            <div class="content-container">
              <RoundResourceIcon
                src="/icons/logout.svg"
                type="resource reverse"
              />
              <h6 class="setting_link--header">
                {{ $t("Components.Sidebar.Logout") }}
              </h6>
            </div>
          </div>
        </div>
      </Card>
      <router-view />
    </div>
  </div>
</template>

<script>
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import { BFormCheckbox } from 'bootstrap-vue';
import AuthService from '@/Services/Auth/auth.service';
import UserState from '@/singletons/user.state.singleton';
import MapNavigatorController from '@/classes/map/map.navigator.controller';
import SettingsNavigatorState from './settings.navigator.state';

export default {
  name: 'SettingsMenu',
  components: {
    Header,
    Card,
    RoundResourceIcon,
    BFormCheckbox,
  },
  data() {
    return {
      UserState,
      CustomEvent,
      MapNavigatorController,
      name: UserState?.profile?.Name,
      email: UserState?.profile?.Username,
    };
  },
  computed: {
    pinSidePage: {
      get: () => SettingsNavigatorState.pinSidePage,
      set: (pin) => SettingsNavigatorState.setPinSidePage(pin),
    },
  },
  methods: {
    async signOut() {
      await AuthService.logout('user:action:signout', true);
      await this.$router.push('/login');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../style/settings';

.settings{
  .edit-container {
    display: flex;
    justify-content: center;
    height: 3rem; // top stop card from jumping up and down in focus
    .settings_icon{
      line-height: 0.9rem;
      vertical-align:baseline;
      margin: auto 0;
    }
    input {
      border: none;
      padding: 0;
      text-align: center;
      font-size: 1.125rem;
      margin-top: 1rem;
      background: transparent;
      color: var(--font-subheader);
      &:focus {
        outline: none !important;
        cursor: text !important;
        border-bottom: 0.0625rem solid var(--primary-font-color);
      }
      &:hover {
        cursor: pointer;
        border-bottom: 0.0625rem solid var(--primary-font-color);
      }
    }
  }
}

.center-text{
  text-align: center;
}
</style>
