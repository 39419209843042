import { render, staticRenderFns } from "./settings.set.navigator.position.view.vue?vue&type=template&id=41252e23&scoped=true"
import script from "./settings.set.navigator.position.view.vue?vue&type=script&lang=js"
export * from "./settings.set.navigator.position.view.vue?vue&type=script&lang=js"
import style0 from "./settings.set.navigator.position.view.vue?vue&type=style&index=0&id=41252e23&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41252e23",
  null
  
)

export default component.exports