<template>
  <div class="settings">
    <Header
      class="flex"
      :title="$t('Components.Settings.settings')"
    >
      <h5 class="header-title">
        {{ $t('Components.Settings.settings') }}
      </h5>
    </Header>

    <div class="scrollable">
      <Card class="center ">
        <SettingsAvatar
          class="avatar"
          :src="UserState.avatar"
          height="120px"
          width="120px"
          :border="true"
          :rounded="true"
          :show-delete="!UserState.defaultAvatar && !UserState.isSSO"
          aria="User Avatar"
          @deleteAvatar="deleteUserAvatar"
        />
        <div>
          <div
            v-if="!UserState.isSSO"
            class="edit-container flex"
          >
            <svg-icon
              class="settings_icon"
              src="/icons/edit.svg"
            />
            <input
              v-model="name"
              class="name"
              :placeholder="name ? name : $t('Components.Settings.enter_name')"
              :style="{ width: reactiveWidth }"
              @change="updateName()"
            >
          </div>
          <div v-else>
            <h6 class="margin margin-medium-large margin-top center-text subheader">
              {{ name }}
            </h6>
            <p class="center-text subheader">
              {{ email }}
            </p>
          </div>
        </div>
      </Card>

      <Card position="bottom">
        <div
          v-for="(setting, index) in getSettings()"
          :key="index"
        >
          <router-link
            :to="setting.link"
          >
            <div class="settings_link--container">
              <div class="content-container">
                <RoundResourceIcon
                  :src="setting.iconPath"
                  type="settings reverse"
                />
                <h6 class="setting_link--text">
                  {{ setting.name }}
                </h6>
              </div>
            </div>
          </router-link>
        </div>

        <div
          @click="signOut()"
        >
          <div class="settings_link--container">
            <div class="content-container">
              <RoundResourceIcon
                src="/icons/logout.svg"
                type="resource reverse"
              />
              <h6 class="setting_link--text">
                {{ $t("Components.Sidebar.Logout") }}
              </h6>
            </div>
          </div>
        </div>
      </Card>
      <router-view />
    </div>
  </div>
</template>

<script>
// /* Components */
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import UserState from '@/singletons/user.state.singleton';
import UserService from '@/Services/User/user.service';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import AuthService from '@/Services/Auth/auth.service';
import SettingsAvatar from './components/settings.avatar.vue';

export default {
  name: 'SettingsMenu',
  components: {
    Header,
    Card,
    SettingsAvatar,
    RoundResourceIcon,
  },
  data() {
    return {
      UserState,
      name: UserState?.profile?.Name,
      email: UserState?.profile?.Username,
    };
  },
  computed: {
    reactiveWidth() {
      return this.name.length > 10 ? `${this.name.length}ch` : '10ch';
    },
  },
  methods: {
    async signOut() {
      await AuthService.logout('user:action:signout', true);
      this.$router.push('/login');
    },
    updateName() {
      UserService.saveProfile('Name', this.name);
    },
    deleteUserAvatar() {
      UserService.deleteAvatar();
    },
    getSettings() {
      return this.generateSettings().filter((a) => a.shown !== false);
    },
    generateSettings() {
      return [
        {
          name: this.$t('Components.Settings.status'),
          iconPath: '/icons/circle.svg',
          shown: true,
          link: '/settings/user-status',
        },
        {
          name: this.$t('Components.Settings.general'),
          iconPath: '/icons/general.svg',
          shown: true,
          link: { name: 'settings-general' },
        },
        {
          name: this.$t('Components.Settings.change_avatar'),
          iconPath: '/icons/camera.svg',
          shown: !UserState.isSSO,
          link: { name: 'change-avatar' },
        },
        {
          name: this.$t('Components.Settings.change_password'),
          iconPath: '/icons/lock.svg',
          shown: !UserState.isSSO,
          link: { name: 'change-password' },
        },
        {
          name: this.$t('Components.Settings.license_agreement'),
          iconPath: '/icons/legal_document.svg',
          shown: true,
          link: { name: 'settings-privacy' },
        },
        {
          name: this.$t('Components.Settings.theme'),
          iconPath: '/icons/themes.svg',
          shown: false,
          link: '/settings/theme',
        },
        {
          name: this.$t('Components.Settings.desk_information'),
          iconPath: '',
          shown: false,
          link: '/settings/desk-info',
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
@import './style/settings.scss';

.settings{
  .edit-container {
    display: flex;
    justify-content: center;
    height: 3rem; // top stop card from jumping up and down in focus
    .settings_icon{
      line-height: 0.9rem;
      vertical-align:baseline;
      margin: auto 0;
    }
    input {
      border: none;
      padding: 0;
      text-align: center;
      font-size: 1.125rem;
      margin-top: 1rem;
      background: transparent;
      color: var(--font-subheader);
      &:focus {
        outline: none !important;
        cursor: text !important;
        border-bottom: 0.0625rem solid var(--primary-font-color);
      }
      &:hover {
        cursor: pointer;
        border-bottom: 0.0625rem solid var(--primary-font-color);
      }
    }
  }
}
.center-text{
  text-align: center;
}
</style>
