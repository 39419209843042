<template>
  <div class="settings">
    <Header
      :back="true"
      :text="$t('Components.Settings.settings')"
      :title="$t('Components.Settings.units')"
    >
      <template v-slot:back-button>
        <ExtendedRouterLink
          :to="{'name':'settings'}"
        >
          <svg-icon
            src="/icons/arrow_left.svg"
          />

          <p> {{ $t('Components.Settings.general') }}</p>
        </ExtendedRouterLink>
      </template>
      <h5 class="center-text header-title">
        {{ $t('Components.Settings.units') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card
        class="bottom no-padding-top"
        position="bottom"
      >
        <div
          class="radio-btn flex margin margin-medium margin-bottom margin-top"
          @click="updateUnits(metric)"
        >
          <RadioButton
            :class="isMetric ? 'checked' : 'not-checked'"
          />
          <p> {{ $t('Components.Settings.metric') }}</p>
        </div>
        <div
          class="radio-btn margin margin-medium margin-bottom margin-top"
          @click="updateUnits(imperial)"
        >
          <RadioButton

            :class="isImperial ? 'checked' : 'not-checked'"
          />
          <p> {{ $t('Components.Settings.imperial') }}</p>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
/* Components */
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import ExtendedRouterLink from '@/components/router/router.link.component.vue';
import RadioButton from '@/components/RadioButton/simple.radio.button.vue';

/* State  & Services */
import UserState from '@/singletons/user.state.singleton';
import UserService from '@/Services/User/user.service';

export default {
  components: {
    Header,
    Card,
    RadioButton,
    ExtendedRouterLink,
  },
  data() {
    return {
      imperial: 'IMPERIAL',
      metric: 'METRIC',
    };
  },
  computed: {
    isImperial() { return UserState?.profile?.MeasurementSystem === this.imperial; },
    isMetric() { return UserState?.profile?.MeasurementSystem === this.metric; },
  },
  methods: {
    updateUnits(value) {
      UserService.saveProfile('MeasurementSystem', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-btn{
  display: flex;
  p{
    margin: 0;
    margin-left: 1rem;
  }
  &:hover{
   cursor: pointer;
  }
}
</style>
