<template>
  <div class="settings">
    <Header
      :back="true"
      :text="$t('Components.Settings.settings')"
      :title="$t('Components.Settings.navigator_position')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Settings.navigator_position') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card position="bottom">
        <!-- current position -->
        <div
          class="settings_link--container"
          @click="showNavigatorMarker()"
        >
          <div class="content-container">
            <RoundResourceIcon
              src="/icons/navigator_position_current_position.svg"
              type="settings reverse"
            />
            <div class="setting_link--content-container">
              <h6 class="setting_link--header">
                {{ $t('Components.Settings.navigator_position_current_position') }}
              </h6>
            </div>
          </div>
          <div class="setting_link--content-container grayed-out-text">
            <p
              v-if="!SettingsNavigatorState.navigatorMarker?.position"
              class="setting_link--text"
            >
              {{ $t('Components.Settings.navigator_position_no_position_set') }}
            </p>
            <div
              v-else
              class="setting_icon--vcenter"
            >
              <svg-icon
                class="end-icon"
                src="/icons/map_navigation_pin.svg"
              />
            </div>
          </div>
        </div>

        <!-- set position -->
        <div
          class="settings_link--container"
          @click="setNavigatorMarkerPosition()"
        >
          <div class="content-container">
            <RoundResourceIcon
              src="/icons/default_view.svg"
              type="settings reverse"
            />
            <div class="setting_link--content-container">
              <h6 class="setting_link--header">
                {{ $t('Components.Settings.navigator_position_set_position') }}
              </h6>
            </div>
          </div>
          <div class="end-icon selection-progress grayed-out-text">
            <p
              v-if="selectionInProgress"
              class="setting_link--text"
            >
              {{ $t('Components.Settings.navigator_position_set_position_select_from_map') }}
            </p>
          </div>
        </div>

        <!-- show position -->
        <div class="settings_link--container">
          <div class="content-container">
            <RoundResourceIcon
              src="/icons/navigator_position_show_position.svg"
              type="settings reverse"
            />
            <div class="setting_link--content-container">
              <h6 class="setting_link--header">
                {{ $t('Components.Settings.navigator_position_show_position') }}
              </h6>
            </div>
          </div>
          <div class="end-icon">
            <BFormCheckbox
              v-model="navigatorMarkerVisible"
              switch
              size="lg"
            />
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Header from '@/components/headers/header.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import Card from '@/components/card/card.vue';
import { BFormCheckbox } from 'bootstrap-vue';
import MapState from '@/singletons/map.state.singleton';
import MapNavigatorController from '@/classes/map/map.navigator.controller';
import MapController from '@/classes/map/map.controller';
import LocationController from '@/views/location.selection/location.selection.controller';
import SettingsNavigatorState from './settings.navigator.state';

export default {
  components: {
    Header,
    RoundResourceIcon,
    Card,
    BFormCheckbox,
  },
  data() {
    return {
      SettingsNavigatorState,
      selectionInProgress: false,
    };
  },
  computed: {
    navigatorMarkerVisible: {
      get() {
        return SettingsNavigatorState.navigatorMarker?.visible;
      },
      set(visible) {
        if (visible) {
          MapNavigatorController.showNavigatorMarker();
          this.selectionInProgress = false;
        } else {
          MapNavigatorController.hideNavigatorMarker();
        }
        MapController.render();
      },
    },
  },
  async created() {
    SettingsNavigatorState.init();
    await MapState.hasFinnishedLoadingPromise;

    // If map has already loaded, bind the event, otherwise wait for map to finish loading a floor before binding it
    if (MapController.selectionManager) {
      MapController.selectionManager.onNavigationMarkerSet(this.navigatorMarkerSet);
    }
    document.addEventListener('onFloorLoaded', this.onFloorLoaded);
  },
  destroyed() {
    MapController.selectionManager.removeListener('on_navigation_marker_set', this.navigatorMarkerSet);
    document.removeEventListener('onFloorLoaded', this.onFloorLoaded);
    MapState.mode = MapState.MODE_TYPE.book;
  },
  methods: {
    onFloorLoaded() {
      MapController.selectionManager.removeListener('on_navigation_marker_set', this.navigatorMarkerSet);
      MapController.selectionManager.onNavigationMarkerSet(this.navigatorMarkerSet);
    },
    setNavigatorMarkerPosition() {
      this.selectionInProgress = !this.selectionInProgress;
      MapState.mode = this.selectionInProgress ? MapState.MODE_TYPE.navigator : MapState.MODE_TYPE.book;
    },
    async switchFloorIfNeeded() {
      const markerFloor = SettingsNavigatorState.navigatorMarker?.floorZid;
      const currentFloor = MapState.floor.Zid;
      if (markerFloor && currentFloor && markerFloor !== currentFloor) {
        await LocationController.setLocationByFloorZid(markerFloor);
      }
    },
    async showNavigatorMarker() {
      if (SettingsNavigatorState.navigatorMarker?.position) {
        await this.switchFloorIfNeeded();
        await MapState.hasFinnishedLoadingPromise;
        MapController.panAndZoomToPosition(SettingsNavigatorState.navigatorMarker.position, 6);
      }
    },
    navigatorMarkerSet(position) {
      MapNavigatorController.setNavigatorMarker(position);
      this.selectionInProgress = false;

      // Reset map to booking state
      MapState.mode = MapState.MODE_TYPE.book;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../style/settings';
  .grayed-out-text {
    p {
      color:  var(--secondary-color-light);
    }
  }
  .selection-progress {
    justify-content: space-between;
    p {
      margin: 0rem;
    }
  }
  .end-icon {
    fill: var(--primary-color);
    svg {
      fill: var(--primary-color);
    }
  }
</style>
